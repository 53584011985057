import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/web-toit.io/web-toit.io/src/components/MdxLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageTitle title="Cookies policy" subTitle="Last updated: February 2, 2021." mdxType="PageTitle" />
    <Section css={{
      textAlign: "left"
    }} mdxType="Section">
      <p>{`To make this site work properly, we sometimes place small data files called cookies on your device. Most big websites do this too. By using our website, you agree that this cookies policy will apply whenever you access the website on any device. Any changes to our cookies policy will be posted here. We reserve the right to update our cookies policy and such changes shall become effective as soon as they are posted. Your continued use of our website constitutes your agreement of all such changes.`}</p>
      <h2>{`What are cookies?`}</h2>
      <p>{`A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.`}</p>
      <h2>{`How do we use cookies?`}</h2>
      <p>{`We use cookies to register and track the traffic on our website. The main purpose is to improve on our website performance and your experience of our website. We use `}<a parentName="p" {...{
          "href": "https://segment.com/"
        }}>{`Segment`}</a>{` to gather data, which is shared with `}<a parentName="p" {...{
          "href": "https://amplitude.com/"
        }}>{`Amplitude`}</a>{`, `}<a parentName="p" {...{
          "href": "https://www.hubspot.com/"
        }}>{`HubSpot`}</a>{` and `}<a parentName="p" {...{
          "href": "https://analytics.google.com/"
        }}>{`Google Analytics`}</a>{` for analytics.`}</p>
      <p>{`Enabling these cookies is not strictly necessary for the website to work but it will provide you with a better browsing experience. You can delete or block these cookies, but if you do that some features of this site may not work as intended.`}</p>
      <p>{`The cookie-related information is not used to identify you personally and the pattern data is fully under our control. These cookies are not used for any purpose other than those described here.`}</p>
      <h2>{`How to control cookies`}</h2>
      <p>{`You can control and/or delete cookies as you wish – for details, see `}<a parentName="p" {...{
          "href": "https://cookiesandyou.com/"
        }}>{`Cookies & You`}</a>{`. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      